import React, { useEffect, useState, useRef } from "react";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaLeaf } from "react-icons/fa"; // For Veg icon
import { GiChickenLeg } from "react-icons/gi"; // For Non-Veg icon
import { MdLocationOn } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ItemCard from "./ItemCard";
import menuDeault from "./MenuItems";
import { QRCodeCanvas as QRCode } from "qrcode.react";

const MenuWithSavedItems = (props) => {
  let menuFromProps = props.menu;
  let restaurantName = props.restaurantName;
  let instagramLink = props.instagramLink;
  let qrLink = props.qrLink;
  let locationLink = props.locationLink;
  const [savedItems, setSavedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showQR, setShowQR] = useState(false); // State for showing QR modal

  const { restaurantId } = useParams();
  const navigate = useNavigate();
  console.log({ menuFromProps });
  let menu = [];
  if (menuFromProps && menuFromProps.length) {
    menu = menuFromProps;
  } else {
    menu = menuDeault;
  }
  const categoryRefs = useRef({}); // Object to hold references for categories

  useEffect(() => {
    const saved =
      JSON.parse(localStorage.getItem(`ResturentSavedItems-${restaurantId}`)) ||
      [];
    setSavedItems(saved);
  }, [restaurantId]);

  const handleSaveItem = (item) => {
    const itemIndex = savedItems.findIndex((saved) => saved.id === item.id);
    const updatedItems =
      itemIndex !== -1
        ? savedItems.filter((saved) => saved.id !== item.id)
        : [...savedItems, { ...item }];
    setSavedItems(updatedItems);
    localStorage.setItem(
      `ResturentSavedItems-${restaurantId}`,
      JSON.stringify(updatedItems)
    );
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const ref = categoryRefs.current[category];
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTimeout(() => {
        window.scrollBy({ top: -100, behavior: "smooth" });
      }, 1000);
    }
  };

  return (
    <div className="p-4">
      <header className="text-center mb-4">
        <h1 className="text-3xl font-bold text-teal-600">
          {restaurantName || "Digital Menu Card "}
        </h1>
        <p className="text-gray-600 text-sm">Explore our delicious menu</p>
        <div className="flex justify-center items-center gap-6 mt-4">
          <div className="flex items-center gap-4">
            <a
              href={locationLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-Black flex items-center mt-2 bg-blue-600 text-white px-2 py-2 rounded-lg hover:bg-blue-700"
            >
              <img
                src={require("../assets/logos/google-maps.png")}
                alt="Google Maps"
                className="w-12 h-12 mr-1"
              />
              Google Maps Location
            </a>
          </div>
          <div className="flex items-center gap-2">
            <a
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-Black flex items-center mt-2 bg-pink-600 text-white px-2 py-2 rounded-lg hover:bg-pink-700"
            >
              <img
                src={require("../assets/logos/instagram.png")}
                alt="Google Maps"
                className="w-12 h-12 mr-1"
              />
              Follow on Instagram
            </a>
          </div>
        </div>
        {/* Payment Methods Section */}
        <div className="mt-4">
          <div className="flex justify-center items-center gap-4 mt-2">
            <a
              href={qrLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/phonepe.png")}
                alt="PhonePe"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">PhonePe</p>
            </a>
            <a
              href={qrLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/paytm.png")}
                alt="Paytm"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">Paytm</p>
            </a>
            <a
              href={qrLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/googlepay.png")}
                alt="GPay"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">GPay</p>
            </a>
            <div
              onClick={() => setShowQR(true)}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1 shadow-lg"
            >
              <img
                src={require("../assets/logos/qr-1.png")}
                alt="View QR Code"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">View QR Code</p>
            </div>
          </div>
        </div>
        {showQR && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-lg font-bold text-teal-600 mb-4">
                Scan to Pay
              </h2>
              <QRCode value={qrLink} size={200} includeMargin />
              <button
                onClick={() => setShowQR(false)}
                className="mt-4 bg-teal-600 text-white px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </header>

      <div className="flex overflow-x-auto gap-4 pb-4 mb-6 border-b border-gray-300 sticky top-0 pt-6 bg-white">
        <button
          onClick={() => handleCategoryClick("All")}
          className={`px-6 py-2 rounded-full ${
            selectedCategory === "All"
              ? "bg-teal-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-700"
          } whitespace-nowrap font-medium`}
        >
          All
        </button>
        {menu.map((category, index) => (
          <button
            key={index}
            onClick={() => handleCategoryClick(category.category)}
            className={`px-6 py-2 rounded-full ${
              selectedCategory === category.category
                ? "bg-teal-600 text-white shadow-lg"
                : "bg-gray-200 text-gray-700"
            } whitespace-nowrap font-medium`}
          >
            {category.category}
          </button>
        ))}
      </div>

      <div>
        {menu.map((cat) => (
          <div
            key={cat.category}
            ref={(el) => (categoryRefs.current[cat.category] = el)}
            className="mb-8"
          >
            <h2 className="text-xl font-bold text-teal-600 mb-4">
              {cat.category}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cat.items.map((item, index) => (
                <ItemCard
                  item={item}
                  index={index}
                  handleSaveItem={handleSaveItem}
                  savedItems={savedItems}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {savedItems.length > 0 && (
        <footer className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 p-4 flex justify-between items-center">
          <p className="text-gray-700">
            {savedItems.length} item{savedItems.length !== 1 && "s"} saved
          </p>
          <button
            onClick={() => navigate(`/menu/${restaurantId}/Saved`)}
            className="flex items-center gap-2 bg-teal-600 text-white px-4 py-2 rounded-lg"
          >
            <AiOutlineShoppingCart size={20} />
            View Saved Items
          </button>
        </footer>
      )}
    </div>
  );
};

export default MenuWithSavedItems;

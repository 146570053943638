import React, { useState } from "react";
import { FaLeaf } from "react-icons/fa"; // Veg icon
import { GiChickenLeg } from "react-icons/gi"; // Non-Veg icon
import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const RestaurantForm = () => {
  const [restaurantName, setRestaurantName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [menuInput, setMenuInput] = useState(""); // For user input
  const [menuData, setMenuData] = useState(null); // Parsed menu data
  const [docId, setDocId] = useState(""); // Document ID for update
  const [savedDetails, setSavedDetails] = useState(null); // Details of saved document
  const [loading, setLoading] = useState(false); // Loading state

  const handlePreview = () => {
    try {
      // Evaluate the input as a JavaScript object
      const parsedMenu = eval(`(${menuInput})`);

      // Check if the parsed data is valid
      if (!Array.isArray(parsedMenu)) {
        throw new Error("Menu should be an array of categories.");
      }

      setMenuData(parsedMenu); // Save the parsed menu data for preview
    } catch (error) {
      alert(`Invalid menu format: ${error.message}`);
    }
  };

  const handleSaveOrUpdate = async () => {
    console.log(menuData);
    if (!restaurantName || !address || !phoneNumber || !menuData) {
      alert("Please fill all fields and preview the menu before saving!");
      return;
    }

    setLoading(true); // Start loading
    try {
      let response;
      if (docId) {
        // Update existing document
        const docRef = doc(db, "restaurants", docId);
        await updateDoc(docRef, {
          restaurantName,
          address,
          phoneNumber,
          menu: menuData,
        });
        response = { id: docId, restaurantName }; // Simulate response
      } else {
        // Add new document
        const docRef = await addDoc(collection(db, "restaurants"), {
          restaurantName,
          address,
          phoneNumber,
          menu: menuData,
        });
        response = { id: docRef.id, restaurantName };
      }

      setSavedDetails(response); // Save details of the document
      alert("Restaurant and menu saved successfully!");
    } catch (error) {
      console.error("Error saving/updating restaurant:", error);
      alert("Failed to save/update restaurant. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="p-4">
      {/* Form Section */}
      <h1 className="text-2xl font-bold mb-4">Add/Update Restaurant</h1>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Restaurant Name</label>
          <input
            type="text"
            value={restaurantName}
            onChange={(e) => setRestaurantName(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter restaurant name"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Address</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter address"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter phone number"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">
            Menu (JavaScript Object)
          </label>
          <textarea
            value={menuInput}
            onChange={(e) => setMenuInput(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder='Enter menu as a JavaScript object, e.g., [{category:"Hot Selling",items:[{name:"Choley Bhature",price:184,type:"Veg"}]}]'
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium">
            Document ID (Optional for Update)
          </label>
          <input
            type="text"
            value={docId}
            onChange={(e) => setDocId(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter document ID to update (leave empty to create new)"
          />
        </div>
        <div className="flex gap-4">
          <button
            type="button"
            onClick={handlePreview}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Preview Menu
          </button>
          <button
            type="button"
            onClick={handleSaveOrUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            {loading
              ? "Saving..."
              : docId
              ? "Update Menu"
              : "Save to Firestore"}
          </button>
        </div>
      </form>
      {savedDetails && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Saved Details</h2>
          <p>
            <strong>Restaurant Name:</strong> {savedDetails.restaurantName}
          </p>
          <p>
            <strong>Document ID:</strong> {savedDetails.id}
          </p>
        </div>
      )}
      {/* Menu Preview Section */}
      {menuData && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Menu Preview</h2>
          {menuData.map((category, index) => (
            <div key={index} className="mb-6">
              <h3 className="text-lg font-semibold mb-2">
                {category.category}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {category.items.map((item, idx) => (
                  <div
                    key={idx}
                    className="bg-white shadow-md rounded-lg p-4 flex gap-4"
                  >
                    <div>
                      <h4 className="text-lg font-semibold">{item.name}</h4>
                      <p className="text-sm text-gray-600">
                        {item.description || "No description available"}
                      </p>
                      {/* <p className="text-lg font-bold text-teal-600">
                        ₹{item.price.toFixed(2)}
                      </p> */}
                      <div className="flex items-center mt-2">
                        {item.type === "Veg" ? (
                          <FaLeaf className="text-green-600 mr-2" />
                        ) : (
                          <GiChickenLeg className="text-red-600 mr-2" />
                        )}
                        <span className="text-sm font-medium">{item.type}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Saved Details Section */}
    </div>
  );
};

export default RestaurantForm;

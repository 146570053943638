import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import MenuWithSavedItems from "../Rest/MenuCard";

const MenuScreen = () => {
  const { restaurantId } = useParams(); // Get the restaurant ID from the route params
  const [restaurantData, setRestaurantData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        setLoading(true);

        // Check sessionStorage for cached data
        const cachedData = sessionStorage.getItem(`restaurant-${restaurantId}`);
        if (cachedData) {
          setRestaurantData(JSON.parse(cachedData));
          setLoading(false);
          console.log("GOT THE DATA FROM SESSION STORAAGE");
          return;
        }

        // Fetch data from Firestore if not cached
        const docRef = doc(db, "restaurants", restaurantId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = { id: docSnap.id, ...docSnap.data() };
          console.log("GOT THE DATA FROM FIREBASE STORAAGE");

          // Cache the data in sessionStorage
          sessionStorage.setItem(
            `restaurant-${restaurantId}`,
            JSON.stringify(data)
          );

          // Update state with fetched data
          setRestaurantData(data);
        } else {
          throw new Error("Restaurant not found");
        }
      } catch (err) {
        console.error("Error fetching restaurant data:", err);
        setError(err.message || "An error occurred while fetching the data");
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantData();
  }, [restaurantId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  console.log({ restaurantData });
  return (
    <MenuWithSavedItems
      menu={restaurantData.menu}
      restaurantName={restaurantData.restaurantName}
      qrLink={restaurantData.qrLink}
      instagramLink={restaurantData.instagramLink}
      locationLink={restaurantData.locationLink}
    />
  );
};

export default MenuScreen;
